
import { useComments, hotComments, usePost } from '@/modules/community/communities/community'
import { Comment } from '@/modules/community/communities/community.api'
import useComment from '@/modules/community/communities/useComment'
import Holder from '@/provider/Holder.vue'
import Scroller from 'common/providers/Scroller.vue'
import { ComponentPublicInstance, computed, defineComponent, shallowRef } from 'vue'
import TheInputTool from '@/modules/community/communities/components/TheInputTool.vue'
import ThePostTemplate from '@/modules/community/communities/components/ThePostTemplate.vue'
import TheImgGallery from '@/modules/community/communities/components/TheImgGallery.vue'
import TheLikeButton from '@/modules/community/communities/components/TheLikeButton.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Button from '@/components/Button.vue'
import TheCommentLine from '@/modules/community/communities/components/TheCommentLine.vue'
import TheComment from '@/modules/community/communities/components/TheCommentItem.vue'

export default defineComponent(
  {
    name: 'Post',
    components: {
      Scroller,
      TheComment,
      TheCommentLine,
      Button,
      PageWithHeader,
      TheLikeButton,
      TheImgGallery,
      ThePostTemplate,
      TheInputTool,
      Holder,
    },
    // emits: ['refresh'],
    props: {
      postId: {
        type: String,
        required: true,
      },
    },
    setup (props) {
      const post = usePost(props.postId)
      const { comments, refresh, loadMore, response } = useComments(props.postId)
      const { commentsHot } = hotComments(props.postId)
      const commentActive = shallowRef(false)
      const sendBtn = shallowRef<ComponentPublicInstance | null>(null)

      const hasImages = computed(() => post.value && post.value.imageUrls?.length)

      const deactivatedComment = () => {
        commentActive.value = false
      }

      // 评论成功回调
      const done = () => {
        deactivatedComment()
        refresh()
      }

      const {
        text, sendImg: _sendImg, send, reset: _reset, reply: _reply,
      } = useComment(post, done)

      // 发送图片 触发评论动作
      const sendImg = (imgs: Array<string>) => {
        _sendImg(imgs[0])
        ;(sendBtn.value as ComponentPublicInstance).$el.click()
      }

      const reply = (comment: Comment) => {
        _reply(comment)
        commentActive.value = true
      }

      // 关闭并重置输入框
      const deactivate = () => {
        _reset()
        deactivatedComment()
      }

      return {
        hasImages,
        post,
        comments,
        response,
        commentActive,
        commentsHot,
        text,
        sendBtn,
        sendImg,
        reply,
        send,
        deactivate,
        loadMore,
      }
    },
  },
)
