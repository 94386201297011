<template>
<PageWithHeader class="post">
  <template #title>
    <t path="post_2">Post</t>
  </template>
  <template #body>
    <Scroller @bottomTouched="loadMore" class="flex-1">
      <div class="pt-16 px-12 mb-16">
        <Holder :content="post" :skeleton-repeat="1">
          <ThePostTemplate :post="post" detail use-translate />
          <TheImgGallery v-if="hasImages" :images="post.imageUrls" class="my-16" />
        </Holder>
        <Holder :content="commentsHot" :skeleton-repeat="4" no-empty>
          <TheCommentLine :num="commentsHot && commentsHot.length" :isHot="true" />
          <TheComment
            v-for="comment in commentsHot" :key="comment.commentId" :comment="comment"
            @reply="reply"
          />
        </Holder>
        <Holder :content="comments" :skeleton-repeat="4" no-empty>
          <TheCommentLine :num="response && response.size" />
          <TheComment
            v-for="comment in comments" :key="comment.commentId" :comment="comment"
            @reply="reply"
          />
        </Holder>
      </div>
    </Scroller>
  </template>
  <template #footer>
    <div v-if="post" class="fake__input df-middle px-16">
      <t as="div" path="post_6" class="anchor flex-1 px-16 py-4 mr-32"
        @click="commentActive = true">
        Leave a comment...
      </t>
      <TheLikeButton
        :id="post.postId" #="{ count }"
        :user="post.userId" :count="post.likeCount"
        :type="1" :like="post.likeStatus" class="like-icon"
      >
        <span class="like-count">{{count}}</span>
      </TheLikeButton>
    </div>
    <template v-if="commentActive">
      <div class="mask spread" @click="deactivate"></div>
      <TheInputTool v-model:content="text" class="float__input" @useImages="sendImg">
        <div class="flex-1 t-right mr-16">
          <Button ref="sendBtn" class="primary send" @click="send">
            <t path="post_9" #="{td}" custom>{{td || 'Send'}}</t>
          </Button>
        </div>
      </TheInputTool>
    </template>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import { useComments, hotComments, usePost } from '@/modules/community/communities/community'
import { Comment } from '@/modules/community/communities/community.api'
import useComment from '@/modules/community/communities/useComment'
import Holder from '@/provider/Holder.vue'
import Scroller from 'common/providers/Scroller.vue'
import { ComponentPublicInstance, computed, defineComponent, shallowRef } from 'vue'
import TheInputTool from '@/modules/community/communities/components/TheInputTool.vue'
import ThePostTemplate from '@/modules/community/communities/components/ThePostTemplate.vue'
import TheImgGallery from '@/modules/community/communities/components/TheImgGallery.vue'
import TheLikeButton from '@/modules/community/communities/components/TheLikeButton.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Button from '@/components/Button.vue'
import TheCommentLine from '@/modules/community/communities/components/TheCommentLine.vue'
import TheComment from '@/modules/community/communities/components/TheCommentItem.vue'

export default defineComponent(
  {
    name: 'Post',
    components: {
      Scroller,
      TheComment,
      TheCommentLine,
      Button,
      PageWithHeader,
      TheLikeButton,
      TheImgGallery,
      ThePostTemplate,
      TheInputTool,
      Holder,
    },
    // emits: ['refresh'],
    props: {
      postId: {
        type: String,
        required: true,
      },
    },
    setup (props) {
      const post = usePost(props.postId)
      const { comments, refresh, loadMore, response } = useComments(props.postId)
      const { commentsHot } = hotComments(props.postId)
      const commentActive = shallowRef(false)
      const sendBtn = shallowRef<ComponentPublicInstance | null>(null)

      const hasImages = computed(() => post.value && post.value.imageUrls?.length)

      const deactivatedComment = () => {
        commentActive.value = false
      }

      // 评论成功回调
      const done = () => {
        deactivatedComment()
        refresh()
      }

      const {
        text, sendImg: _sendImg, send, reset: _reset, reply: _reply,
      } = useComment(post, done)

      // 发送图片 触发评论动作
      const sendImg = (imgs: Array<string>) => {
        _sendImg(imgs[0])
        ;(sendBtn.value as ComponentPublicInstance).$el.click()
      }

      const reply = (comment: Comment) => {
        _reply(comment)
        commentActive.value = true
      }

      // 关闭并重置输入框
      const deactivate = () => {
        _reset()
        deactivatedComment()
      }

      return {
        hasImages,
        post,
        comments,
        response,
        commentActive,
        commentsHot,
        text,
        sendBtn,
        sendImg,
        reply,
        send,
        deactivate,
        loadMore,
      }
    },
  },
)
</script>

<style scoped lang="scss">
.fake__input {
  height: 48px;
  background: var(--color-grey);

  .anchor {
    line-height: 24px;
    background: var(--color-white);
    color: var(--color-tip);
    border-radius: 16px;
  }
}

.float__input {
  min-height: 172px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: auto;
}

.send {
  min-width: 72px;
  height: 28px;
  border-radius: 14px;
}

.like-icon {
  position: relative;
}

.like-count {
  position: absolute;
  top: 0;
  left: 65%;
  padding: 0 4px;
  border-radius: 10px;
  font-size: 10px;
  color: var(--color-white);
  background: var(--color-danger);
}
</style>
