/**
* @description
*   ImgPreviewer.vue of WeTrade
*/
<template>
<span @click.prevent.stop="toggle"><slot></slot></span>
</template>

<script lang="ts">
import transform from 'essential/transform'

const zoom = (img$: HTMLImageElement) => {
  const bounding = img$.getBoundingClientRect()
  const outer$ = document.createElement('div')
  let timer = 0
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let destroyTransform = () => {
  }

  const remove = () => {
    outer$.removeEventListener('click', remove)
    document.body.removeChild(outer$)
    destroyTransform()
    clearTimeout(timer)
  }

  outer$.addEventListener('click', remove)
  outer$.classList.add('spread', 'outer')
  outer$.style.cssText = `
    z-index: 1;
    width: ${img$.width}px;
    height: ${img$.height}px;
    left: ${bounding.x}px;
    top: ${bounding.y}px;
  `

  const zoomImg$ = document.createElement('img')
  zoomImg$.src = img$.src
  zoomImg$.className = 'zoomImg'

  document.body.appendChild(outer$)

  setTimeout(() => {
    outer$.classList.add('expand')

    timer = window.setTimeout(() => {
      outer$.appendChild(zoomImg$)
    }, 200)

    destroyTransform = transform(zoomImg$, {
      onTransform (a) {
        zoomImg$.style.transform = a.toString()
      },
    })
  }, 0)
}

export default {
  name: 'ImgPreviewer',
  setup () {
    const toggle = (e: MouseEvent) => {
      const target = e.target as Element
      if (target.tagName !== 'IMG') return
      zoom(target as HTMLImageElement)
    }

    return {
      toggle,
    }
  },
}
</script>

<style lang="scss">
.zoomImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.outer {
  transition: left .2s, top .2s, width .2s, height .2s;
}

.expand {
  background: rgba(0, 0, 0, 0.8);
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
}
</style>
