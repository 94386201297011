<template>
  <div class="header df-middle f-sm">
    <Pic
      :src="post.avatarPic" fallback="/img/user/Avatar@2x.png"
      width="32" height="32" style="border-radius: 50%"
    />
    <div class="flex-1 ml-16 f-sm">
      <p class="f-bold">{{post.nickName}}</p>
      <Time :time="post.createTime" format="MM-dd-yyyy HH:mm:ss" class="c-tip" />
    </div>
    <slot />
  </div>
  <div class="mt-12 program" style="font-size: 16px;line-height: 24px" :class="{content: !detail}" v-html="formatText" @click="handleClick" />
  <Button v-if="useTranslate" @click="translate" :progress="progress" class="round f-sm mt-24" :class="{primary: isOrigin}" sm style="height: 22px;line-height: 18px">
    <t :path="isOrigin ? 'post_10' : 'post_11'"></t>
  </Button>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useGoogleTranslate from '@/hooks/useGoogleTranslate'
import { renderProgram } from '@/modules/community/communities/community'
import { computed, defineComponent } from 'vue'
import Pic from 'common/Pic.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePostTemplate',
  components: { Button, Time, Pic },
  props: {
    post: {
      type: Object,
      required: true,
    },
    detail: Boolean,
    useTranslate: Boolean,
  },
  setup (props) {
    const { translate, content, progress, isOrigin } = useGoogleTranslate(props.post.content)

    const formatText = computed(() => {
      const text = renderProgram(content.value)
      let textArr = text.split('<br/>')
      textArr = textArr.map(item => {
        return '<p style="margin-bottom: 8px;">' + item + '<p/>'
      })
      return textArr.join('')
    })

    return {
      progress,
      translate,
      isOrigin,
      formatText,
      handleClick (e: Event) {
        if ((e.target as Element)?.tagName === 'A') {
          e.stopPropagation()
        }
      },
    }
  },
})
</script>

<style scoped lang="scss">
.program {
  word-break: break-word;
  line-height: 20px;
}

.content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

</style>
