<template>
  <span class="df-cross-center" :class="{'c-primary': likeState}" @click.prevent="toggleLike">
    <Icon name="thumb-up" :sm="sm" />
    <slot :count="countNo">
      <span class="count d-i ml-4">{{ countNo }}</span>
    </slot>
  </span>
</template>

<script lang="ts">
import { like } from '@/modules/community/communities/community.api'
import { ref, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent(
  {
    name: 'TheLikeButton',
    components: { Icon },
    props: {
      like: Boolean,
      count: {
        type: Number,
        required: true,
        default: 0,
      },
      id: Number,
      user: Number,
      type: Number,
      sm: Boolean,
    },
    setup (props) {
      const likeState = ref(props.like)
      const countNo = ref(props.count)

      return {
        likeState,
        countNo,
        toggleLike () {
          like(
            {
              postUserId: props.user,
              postId: props.id,
              type: props.type,
            },
          ).then(() => {
            countNo.value += (likeState.value ? -1 : 1)
            likeState.value = !likeState.value
          })
        },
      }
    },
  },
)
</script>
