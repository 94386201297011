<template>
  <div class="emoji">
    <div class="emoji__list">
      <Pic
        v-for="item in list"
        :key="item.name" :src="item.src"
        exact @click="pushEmoji(item.name)"
      />
    </div>
    <div class="del">
      <Pic src="/img/community/delete@2x.png" style="display: block; width: 80%" @click="delEmoji" />
    </div>
  </div>
</template>

<script lang="ts">
import emojiList from '@/modules/community/communities/emojis'
import { defineComponent } from 'vue'
import Pic from 'common/Pic.vue'

export default defineComponent(
  {
    name: 'TheEmoji',
    components: { Pic },
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    emits: ['update:value'],
    data () {
      const list: Array<{ src: string; name: string }> = []
      return {
        list,
      }
    },
    methods: {
      getList () {
        emojiList.forEach(a => {
          this.list.push({ src: `/emoji/${a}.png`, name: a })
        })
      },

      // 去:emoji:
      regExp (t: string) {
        const Exp = /^:[^:]+:/
        let val = this.reverse(t)
        val = !Exp.test(val) ? val.slice(1) : val.replace(Exp, '')
        return this.reverse(val)
      },

      reverse (t: string) {
        return t.trim().split('').reverse().join('')
      },

      pushEmoji (n: string) {
        this.updateVal(`${this.value}:${n}:`)
      },

      delEmoji () {
        const n = this.regExp(this.value)
        this.updateVal(n)
      },

      updateVal (t: string) {
        this.$emit('update:value', t)
      },
    },
    created () {
      this.getList()
    },
  },
)
</script>

<style scoped lang="scss">
.emoji {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}

.emoji__list {
  display: flex;
  height: 160px;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  img {
    box-sizing: border-box;
    padding: 8px;
    width: 12.5%;
  }
}

.del {
  padding: 8px;
  width: 12.5%;
  background: #fff;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
