<template>
  <div class="d-f mt-16 card">
    <Pic
      fallback="/img/user/Avatar@2x.png"
      :src="comment.avatarPic" width="32" height="32" style="border-radius: 50%"
    />
    <div class="flex-1 ml-16">
      <p class="c-tip">
        <span class="f-medium f-sm c-text">{{comment.nickName}}</span>
        <TheLikeButton
          :id="comment.commentId"
          :count="comment.likeCount"
          :like="comment.likeStatus" :type="2" :user="comment.fromUserId"
          sm
          class="pull-right f-sm"
        />
      </p>
      <!--引用内容-->
      <div
        v-if="hasQuote"
        class="mt-16 p-8 bg-background c-title"
      >
        <p style="line-height: 20px;" v-html="parentContent" />
        <TheImgGallery
          v-if="comment.toImageUrl" :images="[comment.toImageUrl]" class="margin-top-16"
        />
      </div>
      <!--评论内容-->
      <p class="my-16" style="line-height: 20px;" v-html="content" />
      <TheImgGallery
        v-if="comment.formImageUrl" :images="[comment.formImageUrl]" class="mt-16"
      />
      <p class="mt-20 df-middle">
        <Time :time="comment.createTime" format="MM-dd-yyy HH:mm:ss" class="f-sm c-tip" />
        <t path="post_5"
          class="f-md ml-20 px-8 reply"
          @click="$emit('reply', comment)"
        >Reply</t>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent } from 'vue'
import { renderProgram } from '@/modules/community/communities/community'
import TheImgGallery from './TheImgGallery.vue'
import TheLikeButton from './TheLikeButton.vue'
import Pic from 'common/Pic.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'TheComment',
  components: { Time, Pic, TheImgGallery, TheLikeButton },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  emits: ['reply'],
  setup (props) {
    return {
      hasQuote: R.complement(R.isNil)(props.comment.parentId),
      parentContent: renderProgram(props.comment.parentContent),
      content: renderProgram(props.comment.content),
    }
  },
})
</script>
<style scoped lang="scss">
.reply {
  line-height: 16px;
  border: 1px solid var(--color-title);
  border-radius: 12px;
}
</style>
