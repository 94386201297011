<template>
  <div v-if="num !== 0" class="my-20 t-center com f-md" :class="{ hot: isHot }">
    <t v-if="isHot" path="post_3" custom #="{td}">
      <span class="f-medium">{{td || 'Hot comments'}} {{num}}</span>
    </t>
    <t v-else path="post_4" custom #="{td}">
      <span>{{td || 'All comments'}} {{num}}</span>
    </t>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCommentLine',
  props: {
    num: {
      type: Number,
      default: 0,
    },
    isHot: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
<style scoped lang="scss">
.com {
  position: relative;
  color: var(--color-tip);
}

.hot {
  color: var(--color-asst);
}
</style>
