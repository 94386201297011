/**
* @description
*   TheInputTool.vue of WeTrade
*/
<template>
  <div class="df-col bg-background page__con">
    <div class="input__area flex-1">
      <t path="postpublish_10" custom #="{td}">
        <textarea
          ref="textarea" v-model="text" :placeholder="td || 'Share your wisdom with us'"
          class="textarea bg-background"
        />
      </t>
      <span class="tip">{{typed}}/{{textLimit}}</span>
    </div>
    <slot name="extend" />
    <!-- v-if="imagePreview && images.length" -->
    <TheImgGallery
      v-if="imagePreview"
      :images="images" :max="maxImages" :mutate="mutateImg"
      class="mb-8 mt-12 px-16"
    />
    <TheEmoji v-show="useEmoji" v-model:value="text" />
    <div class="tools df-middle py-8 c-tip">
      <TheImageTool v-if="useImage" class="ml-16 t-center" style="min-width: 70px" @use="useImg" />
      <span class="ml-24 df-middle" @click="useEmoji = !useEmoji">
        <Icon name="smile" class="mr-8" />
        <t path="postpublish_5" custom #="{td}">{{ td || 'Emoji' }}</t>
      </span>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { translate } from '@/i18n'
import { useInput } from '@/modules/community/communities/community'
import { showAlert } from '@/components/popup/popup'
import { computed, shallowRef, onMounted, defineComponent, watch } from 'vue'
import TheEmoji from '@/modules/community/communities/components/TheEmoji.vue'
import TheImageTool from '@/modules/community/communities/components/TheImageTool.vue'
import Icon from '@/components/Icon.vue'
import TheImgGallery from '@/modules/community/communities/components/TheImgGallery.vue'

export default defineComponent(
  {
    name: 'TheInputTool',
    components: { TheImgGallery, Icon, TheImageTool, TheEmoji },
    props: {
      textLimit: {
        type: Number,
        default: 1000,
      },
      imagePreview: Boolean,
      maxImages: {
        type: Number,
        default: 3,
      },
      content: {
        type: String,
        required: true,
      },
      useImage: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['useImages', 'update:images', 'update:content'],
    setup (props, ctx) {
      const textarea = shallowRef<HTMLTextAreaElement | null>(null)
      const { text, images, mutateImg } = useInput()
      const typed = computed(() => text.value.length)
      const useEmoji = shallowRef(false)
      const isMaxImgUsed = computed(() => images.value.length >= props.maxImages)

      onMounted(() => {
        (textarea.value as HTMLTextAreaElement).focus()
      })

      watch(() => props.content, () => {
        text.value = props.content
      })

      watch(text, () => {
        ctx.emit('update:content', text.value)
      })

      watch(images, () => {
        ctx.emit('useImages', images.value)
        ctx.emit('update:images', images.value)
      })

      function useImg (imgSrc: string) {
        if (isMaxImgUsed.value) {
          showAlert(translate('toast_6', 'You can only add 3 images at most'))
        } else {
          mutateImg(imgSrc)
        }
      }

      return {
        textarea,
        text,
        typed,
        images,
        useImg,
        mutateImg,
        useEmoji,
      }
    },
  },
)
</script>

<style scoped lang="scss">

.page__con {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.input__area {
  position: relative;
  height: 80px;
}

.textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 16px;
  resize: none;
  outline: none;
}

.tip {
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: #6D778B;
}

.tools {
  height: 40px;
  background: #fff;
}
</style>
