
import Button from '@/components/Button.vue'
import useGoogleTranslate from '@/hooks/useGoogleTranslate'
import { renderProgram } from '@/modules/community/communities/community'
import { computed, defineComponent } from 'vue'
import Pic from 'common/Pic.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePostTemplate',
  components: { Button, Time, Pic },
  props: {
    post: {
      type: Object,
      required: true,
    },
    detail: Boolean,
    useTranslate: Boolean,
  },
  setup (props) {
    const { translate, content, progress, isOrigin } = useGoogleTranslate(props.post.content)

    const formatText = computed(() => {
      const text = renderProgram(content.value)
      let textArr = text.split('<br/>')
      textArr = textArr.map(item => {
        return '<p style="margin-bottom: 8px;">' + item + '<p/>'
      })
      return textArr.join('')
    })

    return {
      progress,
      translate,
      isOrigin,
      formatText,
      handleClick (e: Event) {
        if ((e.target as Element)?.tagName === 'A') {
          e.stopPropagation()
        }
      },
    }
  },
})
