import showAlert from '@/components/popup/alert'
import { useInput } from '@/modules/community/communities/community'
import { Post, Comment, createComment } from '@/modules/community/communities/community.api'

import { Ref } from 'vue'
import * as R from 'ramda'

export const commentTo =
  (target: Post, content: string, imageUrl: string) => () => {
    const { postId, userId: toUserId } = target
    return createComment(
      {
        postId,
        toUserId,
        content,
        imageUrl,
      },
    )
  }

/**
 * @param comment 目标评论
 * @param postId 帖子ID
 * @param content 文字内容
 * @param imageUrl 图片地址
 */
export const replyTo = (
  comment: Comment, postId: number, content: string, imageUrl: string,
) => () => createComment(
  {
    postId, // 目标帖子
    toUserId: comment.fromUserId, // 回复者ID
    parentId: comment.commentId, // 被回复评论ID
    content, // 回复的文字
    imageUrl, // 回复的图片地址
  },
)

const isEmptyComment = (content: Array<string>) => R.isEmpty(R.reject(R.anyPass([R.isEmpty, R.isNil]), content))

const useComment = (post: Ref<Post | null>, done: Noop) => {
  const { text, images, mutateImg, reset: resetInput } = useInput()
  let isReply = false
  let replyTarget: Comment | null = null

  const reset = () => {
    resetInput()
    isReply = false
    replyTarget = null
  }

  const reply = (target: Comment) => {
    isReply = true
    replyTarget = target
  }

  const send = () => {
    if (!post.value) return
    const content: [string, string] = [text.value, images.value[0]]
    if (isEmptyComment(content)) {
      showAlert('Please type something or select a picture to comment!')
      return
    }
    const request = isReply && replyTarget
      ? replyTo(replyTarget, post.value.postId, ...content)
      : commentTo(post.value, ...content)
    return request()
      .then(R.juxt([reset, done]))
      .catch(() => {
        // 清空图片
        mutateImg(0, images.value.length)
      })
  }

  const sendImg = (img: string) => {
    mutateImg(img)
  }

  return {
    text,
    sendImg,
    reset,
    reply,
    send,
  }
}

export default useComment
